import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { motion } from "framer-motion"
import BackgroundImage from "gatsby-background-image"
import styled from "styled-components"
import { MEDIA_QUERIES } from "../constants"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Title,
  MainHeading,
  InternalLink,
  Text,
} from "../components/styled/Styled"

const Banner = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 5%;
  background-color: transparent;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0;
  }
`

const Section = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  justify-content: center;
  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    width: 100%;
  }
`

const Description = styled(Text)`
  font-size: 1.2rem;
  padding: 10px 5%;

  @media (max-width: ${MEDIA_QUERIES.MEDIUM}) {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

const IndexPage = () => {
  const { banner } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "index-banner.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Banner fluid={banner.childImageSharp.fluid}>
        <Section>
          <MainHeading style={{ paddingRight: "5%", paddingLeft: "5%" }}>
            COMPANY
          </MainHeading>
          <Title style={{ paddingRight: "5%", paddingLeft: "5%" }}>NOI</Title>
          <Description>
            Company NOI is an Antwerp-based dance company founded by Valentina
            Nigro in 2012. ‘Noi’, meaning ‘we’ in Italian, refers both to the
            choreographer’s Italian roots, and to the intense cooperation
            between the dancers, musicians, artists… and the choreographer.
          </Description>
          <InternalLink style={{ marginLeft: "5%" }} to="/production">
            Our Productions
          </InternalLink>
        </Section>
      </Banner>
    </Layout>
  )
}

export default IndexPage
